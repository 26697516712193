import React, { useState, useRef, useCallback, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Logo from '../logo1.jpeg'
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import PhotoCameraRoundedIcon from '@mui/icons-material/PhotoCameraRounded';
import Html5QrcodePlugin from './Html5QrcodePlugin';
import { Html5QrcodeScanner, Html5Qrcode } from "html5-qrcode";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import PalletService from "../Api/PalletService"
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import QrScanner from './qrScanner';

const useStyles = makeStyles((theme) => ({
    root: {
      height: "100%",
      textAlign: 'center',
    },
    imgBox: {
      maxWidth: "80%",
      maxHeight: "80%",
      margin: "10px"
    },
    img: {
      height: "inherit",
      maxWidth: "inherit",
    },
    input: {
      display: "none"
    },
    fontStyle:{
      color:'black',
    },
    buttonColor:{
      backgroundColor: `#3OA3E6`   
     }
  }));
  let propText =  false;
  function useQRCodeScan({
    qrcodeMountNodeID = "",
    closeAfterScan = true,
    getQrBoxDimension,
  }) {
    const [decodedQRData, setDecodedQrData] = useState({
      isScanning: false,
      isScanSuccess: false,
      isScanFailure: false,
      data: null,
      error: "",
    });
    const html5QrCodeScannerRef = React.useRef(null);
  
    // unmount logic
    useEffect(() => {
      return () => {
        if (html5QrCodeScannerRef.current) {
          html5QrCodeScannerRef.current
            ?.stop()
            ?.then((ignore) => {
              // QR Code scanning is stopped
              console.log("stopped after successful scan");
            })
            ?.catch((err) => {
              // Stop failed, handle it.
              console.log("fails to stop after successfull scan result ");
            });
        }
      };
    }, []);
  
    function stopQrCode() {
      if (html5QrCodeScannerRef.current) {
        html5QrCodeScannerRef.current
          ?.stop()
          ?.then((ignore) => {
            // QR Code scanning is stopped
            console.log("stopped after successful scan");
          })
          ?.catch((err) => {
            // Stop failed, handle it.
            console.log("fails to stop after successfull scan result ");
          });
      }
    }
    function startQrCode() {
      try {
        setDecodedQrData({
          ...decodedQRData,
          isScanning: true,
          data: null,
        });
        // eslint-disable-next-line
        const html5qrCodeScanner = new Html5Qrcode(qrcodeMountNodeID);
  
        html5QrCodeScannerRef.current = html5qrCodeScanner;
  
        let qrbox = 250;
        if (getQrBoxDimension) {
          qrbox = getQrBoxDimension();
        }
  
        html5qrCodeScanner
          .start(
            // { deviceId: { exact: cameraId } },
            { facingMode: "environment" },
  
            { fps: 100, qrbox, aspectRatio: 1.777778 },
            (qrCodeMessage) => {
              // do something when code is read
              // console.log('scanned qr code', qrCodeMessage);
  
              setDecodedQrData({
                ...decodedQRData,
                isScanSuccess: true,
                isScanning: false,
                data: qrCodeMessage,
                error: "",
              });
  
              if (closeAfterScan) {
                html5qrCodeScanner
                  .stop()
                  .then((ignore) => {
                    // QR Code scanning is stopped.
                    // setIsOpenCamera(false);
                    console.log("stopped after successful scan");
                  })
                  .catch((err) => {
                    // Stop failed, handle it.
                    console.log("fails to stop after succesfull scan result ");
                  });
              }
            },
            (errorMessage) => {}
          )
          .catch((err) => {
            setDecodedQrData({
              ...decodedQRData,
              isScanSuccess: false,
              isScanning: false,
              isScanFailure: true,
              data: null,
              error: err || "QR Code parsing failed",
            });
          });
      } catch (e) {
        setDecodedQrData({
          ...decodedQRData,
          isScanSuccess: false,
          isScanning: false,
          isScanFailure: true,
          data: null,
          error: e || "QR Code parsing failed",
        });
      }
    }
  
    return {
      startQrCode,
      decodedQRData,
      stopQrCode,
    };
  }
  export default function Associate() {
    const classes = useStyles();
    const [source, setSource] = useState("");
    const [qrCodeData, setQrCodeData] = useState("");
    const [pallet, setPallet] = useState("");
    const [qrValue, showQrValue] = useState(false);
    const [palletValue, showPalletValue] = useState(false);
    const [successBox, setSuccessBox] = useState(false);
    const [hasRender, setRender] = useState(false);
    const { startQrCode, decodedQRData } = useQRCodeScan({
      qrcodeMountNodeID: "qrcodemountnode",
    });

    const handleCapture = (target) => {
        if (target.files) {
          if (target.files.length !== 0) {
            const file = target.files[0];
            const newUrl = URL.createObjectURL(file);
            setSource(newUrl);
          }
        }
    };

    useEffect(() => {
      if(hasRender == true){
        startQrCode();
      }
      }, [hasRender]);

    useEffect(() => {
        if(decodedQRData.data != null){
          setRender(!hasRender)
          getPosition(decodedQRData.data)
        }      
      }, [startQrCode, decodedQRData ]);
  
    const onNewScanResult = useCallback((decodedText, decodedResult) => {
      //getPosition(decodedText)
      if(qrCodeData == decodedText){
        this.html5QrcodeScanner.clear().then((ignore) => {
          console.log(ignore)
        }).catch(error => {
          console.error("Failed to clear html5QrcodeScanner. ", error);
        });
      }      
      // Handle the result here.
      /* if(qrCodeData == decodedText){
        Html5QrcodeScanner.stop().then((ignore) => {
          // QR Code scanning is stopped.
          console.log(ignore)
        }).catch((err) => {
          // Stop failed, handle it.
          console.log(err)
        });
      }else{
        getPosition(decodedText)
      }      */
      
  },[],);

  const getPosition = (text) =>{
    let data = {
      "scannedText": text,
    }
    PalletService.xyPosition(data).then(d => {
      let xy = d && d.data && d.data.data  && d.data.data.xyposition && d.data.data.xyposition 
      setPallet(xy);
      setQrCodeData(text);
    });
  }

  const handleClickShowQr = () => {
    showQrValue(!qrValue)
  }

  const handleClickShowPallet = () => {
    showPalletValue(!palletValue)
  }

  const onSubmit = () => {
    let data = {
      scannedText: qrCodeData,
      xyposition: pallet
    }
    PalletService.registerAssociate(data).then(d => {
      setSuccessBox(true)
    });
  }

  const handleSnackClose = () => {
    setSuccessBox(false)
  }

  const onCameraclick =() =>{
    sessionStorage.setItem('openCam', true);
    setRender(!hasRender)
  }

    return (
            <Grid container spacing={6}>
                <Grid item xs={12} style={{textAlign: `center`}}>
                    <h5>Scan Your QR</h5>
                    {source &&
                        <Box display="flex" justifyContent="center" border={1} className={classes.imgBox}>
                        <img src={source} alt={"snap"} className={classes.img}></img>
                    </Box>}
                    {/* <input
                        accept="image/*"
                        className={classes.input}
                        id="icon-button-file"
                        type="file"
                        capture="environment"
                        onChange={(e) => handleCapture(e.target)}
                    /> */}
                   {/*  <Html5QrcodePlugin 
                    fps={10}
                    qrbox={250}
                    id="icon-button-file"
                    disableFlip={false}
                    qrCodeSuccessCallback={onNewScanResult}
                    propText={propText}/> */}
                    
                    {/* <QrScanner 
                    id="icon-button-file"
                    hasRender={hasRender}/> */}
                  {hasRender &&(
                    <div id="qrcodemountnode"></div>
                  )}
                    {!hasRender &&(
                    <label htmlFor="icon-button-file"
                    >
                    <IconButton                    
                    aria-label="upload picture"
                    component="span"
                    onClick={onCameraclick}
                    >
                    <PhotoCameraRoundedIcon fontSize="large" color="#FFFFFF" />
                    </IconButton>
                    
                </label>
                )}
                
                
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={8} sm={10} md={10}>
                    <InputLabel htmlFor="outlined-adornment-password"
                    className={classes.fontStyle}>Qr Code</InputLabel>
                       <OutlinedInput                      
                       disabled                    
                      id="outlined-adornment-password"
                      style ={{width: '100%', backgroundColor: `white`}}
                      type={qrValue ? 'text' : 'password'}
                      value={qrCodeData}
                      label="Password"
                      
                    />
                    </Grid>
                    <Grid item xs={4} sm={2} md={2} style={{textAlign: `center`}}>
                      <Button size="large" variant="contained" 
                      className={classes.buttonColor}
                      style={{textColor: 'white', marginTop: `20px`, backgroundColor: `#2AABEE`}}
                       onClick={handleClickShowQr}>
                      {qrValue ? 'Hide' : 'Show'}
                            <IconButton
                                    aria-label="toggle password visibility"
                                    
                                    edge="end"
                                  >
                          {qrValue ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                        
                    </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  
                    <Grid container spacing={2}>
                      <Grid item xs={8} sm={10} md={10}>
                      <InputLabel htmlFor="outlined-adornment-password1" 
                      className={classes.fontStyle}>Pallet</InputLabel>
                        <OutlinedInput
                         className={classes.buttonColor}
                        disabled
                        id="outlined-adornment-password1"
                        type={palletValue ? 'text' : 'password'}
                        value={pallet}
                        label="Password"
                        style ={{width: '100%', backgroundColor: `white`}}
                      />
                      </Grid>
                      <Grid item xs={4} sm={2} md={2} style={{textAlign: `center`}}>
                        <Button size="large" variant="contained" 
                        className={classes.buttonColor}
                        style={{textColor: 'white', marginTop: `20px`, backgroundColor: `#2AABEE`}}
                        onClick={handleClickShowPallet}>
                        {palletValue ? 'Hide' : 'Show'}
                              <IconButton
                                      aria-label="toggle password visibility"
                                      
                                      edge="end"
                                    >
                            {palletValue ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                          
                      </Button>
                      </Grid>
                    </Grid>
                 
                </Grid><br />
                <Grid item xs={12}style={{textAlign: `center`}}>
                <Button size="large" variant="contained" 
                 className={classes.buttonColor}
                  style={{textColor: 'white', backgroundColor: `#2AABEE`}}
                  disabled={pallet.length == 0}
                  onClick={onSubmit}>
                  Associate
                        
                  </Button>
                </Grid>
                <Snackbar
                open={successBox}
                autoHideDuration={6000}
                onClose={handleSnackClose}
                message={"Associated Successfully"}
                //action={action}
                />
            </Grid>
        
    )


}