import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Associate from '../pages/associate'
import Disassociate from '../pages/disassociate'

TabPanel.propTypes =  {
  children: PropTypes.node.isRequired,
  index: PropTypes.number,
  value: PropTypes.number,
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}





export default function CenteredTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (e, value) => {
    console.log(e)
    console.log("value", value)
    setValue(value);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={value} onChange={handleChange} 
      //textColor="#FFFFFF"
      textColor="primary"
      variant="scrollable"
      scrollButtons="auto"
      //indicatorColor="primary"
      aria-label="basic tabs example"
      TabIndicatorProps={{
        style: {
          backgroundColor: "#2AABEE",
         
        },
      }}>
          <Tab label="Associate" {...a11yProps(0)} />
          <Tab label="Disassociate" {...a11yProps(1)} />
        </Tabs>
      </Box>
        <TabPanel value={value} index={0}>
          <Associate />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Disassociate />
        </TabPanel>  
    </>
  );
}