import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import MUIDataTable from "mui-datatables";
import Button from '@mui/material/Button';
import PalletService from "../Api/PalletService"
import Snackbar from '@mui/material/Snackbar';
import { ThemeProvider } from "@mui/styles"; 
import { createTheme } from "@mui/material/styles";

let selectedId = [];

const getMuiTheme =createTheme({
  components: {
    MUIDataTableHeadCell: {
      fixedHeader: {
        background: 'blue'
      }
    },
    MUIDataTableBodyCell: {
      styleOverrides:{
        stackedHeader:{
          display:"auto"
        }
      }}
    }
})

export default function Disassociate() { 
  const [list, setList] = useState([]);
  const [successBox, setSuccessBox] = useState(false);
  const [id, setId] = useState(false);;

    const columns = [
        {
         name: "pallotText",
         label: 'PalletText'
         
        },
        {
         name: "xyposition",
         label: 'Position'
        }
       ];
             
       const options = {
         filterType: 'multiselect',
         responsive:'stacked',
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
            <div></div>
          ),
          onRowSelectionChange: (currentSelect, allSelected) => {           
            let result = allSelected.map(item => { return list.at(item.index) });
            let selectedIds = result.map(item => {
              let data = {
                scannedText: item.pallotText,
                xyposition: item.xyposition
              }
                 return data;
            }); 
            //console.log(selectedIds);      
            selectedId = selectedIds
            console.log(selectedId); 
            if(selectedId.length >0){
              setId(true)
            }
            else{
              setId(false)
            }
        },
         search: false,
        filter: false,        
        download: false,
        serverSide: false,
        viewColumns: false,
        print: false,
        pagination: false,
       
       };

    useEffect(() => {
      PalletService.disAssociateList().then(d => {
        console.log(d)
            let data = d && d.data && d.data.data && d.data.data           
            setList(data)
        });
    }, []);

    const onSubmit = () => {
      selectedId.map((item) => {
        let data = {
          scannedText: item.scannedText,
          //xyposition: item.xyposition
        }
        PalletService.registerDisAssociate(data).then(d => {
          setSuccessBox(true)
        });        
      })      
    }
    
    const handleSnackClose = () => {
      setSuccessBox(false)
    }

    return (
        
        <Grid container spacing={6}>
              <Grid item xs={12} style={{textAlign: `center`}}>
                {list &&(
                <ThemeProvider theme={getMuiTheme}>
                  <MUIDataTable
                    data={list}
                    columns={columns}
                    options={options}
                  />
                </ThemeProvider>
                )}                
             </Grid>
             <Grid item xs={12}style={{textAlign: `center`}}>
                <Button size="large" variant="contained" color="secondary"
                 style={{textColor: 'white', backgroundColor: `#2AABEE`}}
                 disabled={!id}
                  onClick={onSubmit}>
                  disassociate
                        
                  </Button>
             </Grid>
                <Snackbar
                open={successBox}
                autoHideDuration={6000}
                onClose={handleSnackClose}
                message={"Disassociated successfully"}
                //action={action}
                />
        </Grid>
  
)

}