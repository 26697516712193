import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Logo from '../logo1.jpeg'

export default function Header() {
    return (
        
            <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            >
            
                <img src={Logo} style={{height: `50px`, }}/> 
            
            </Grid>
      
    )


}
/*borderRadius: '50%' */