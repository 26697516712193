import http from "./DataService";

const registerAssociate = (data) => {
    return http.post("rtls/associate",data);
}

const registerDisAssociate = (data) => {
    return http.post("rtls/disassociate",data);
}

const disAssociateList = () => {
    return http.get(`rtls/list`); 
}


const xyPosition = (data) => {
    return http.get(`rtls/xyposition?scannedText=${data.scannedText}`); 
}

export default {
    registerAssociate, xyPosition,
    disAssociateList, registerDisAssociate
}