import React from 'react';
import logo from './logo.svg';
import './App.css';
import CenteredTabs from './components/tabs'
import Grid from '@mui/material/Grid';
import Header from './components/header';

function Common() {
  return (
    <div>
       <Grid container>
          <Grid item xs={12} md={12} sm={12}>
            <Header />
          </Grid>
          <Grid item xs={12} md={12} sm={12}>
            <CenteredTabs />
          </Grid>
        </Grid>
       
    </div>
  );
}

export default Common;
